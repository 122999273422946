import { useStaticQuery, graphql } from 'gatsby';

export const useRecommendations = () => {
  const {
    allStrapiRecommendation: { nodes },
  } = useStaticQuery(graphql`
    {
      allStrapiRecommendation {
        nodes {
          title
          key
          id
          products {
            realPrice
            title
            subtitle
            salePrice
            url
            labels {
              id
              name
            }
            image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    width: 320
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  return nodes || [];
};
