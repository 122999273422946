import React from 'react';
import cn from 'classnames';
import type { ActionArgs } from '@customTypes/action';
import styles from './Button.module.scss';

type ButtonComponent = {
  action: ActionArgs;
};

export function Button({ action }: ButtonComponent) {
  return (
    <button
      type="button"
      className={cn({
        [styles.button]: true,
        [styles.blueButton]: action.buttonColor === '#213FB4',
      })}
      key={`button--${action.actionText}`}
      style={{
        color: action.buttonTextColor,
        background: action.buttonColor,
        padding: '16px 24px',
      }}
    >
      <div className={styles.buttonInner}>
        <span className={styles.buttonTitle}>{action.actionText}</span>
      </div>
    </button>
  );
}
