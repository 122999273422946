import React from 'react';
import cn from 'classnames';
import type { ActionArgs } from '@customTypes/action';
import styles from './PriceButton.module.scss';

type ButtonComponent = {
  action: ActionArgs;
};

export function PriceButton({ action }: ButtonComponent) {
  return (
    <button
      type="button"
      className={cn({
        [styles.button]: true,
        [styles.bluePriceButton]: action.buttonColor === '#213FB4',
      })}
      key={`button--${action.ActionSubtitle}`}
      style={{
        color: action.buttonTextColor,
        background: action.buttonColor,
        padding: '8px 24px',
      }}
    >
      <div className={styles.buttonInner}>
        <span className={styles.buttonTitle}>{action.actionText}</span>
        <span className={styles.buttonSubtitle}>{action.ActionSubtitle}</span>
      </div>
    </button>
  );
}
