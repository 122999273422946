import React from 'react';
import cn from 'classnames';

import type { ProductType } from '@customTypes/product';

import { Header } from '../Header';
import { Products } from '../Products';
import styles from './ProductsBlock.module.scss';

interface FilteredProductsBlockArg {
  header?: string;
  products: ProductType[];
}

export function FilteredProductsBlock({ products, header }: FilteredProductsBlockArg) {
  return (
    <>
      {header && (
        <div
          className={cn({
            [styles.header]: true,
          })}
        >
          <Header littleHeader>{header}</Header>
        </div>
      )}
      <Products products={products} />
    </>
  );
}
