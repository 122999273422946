import { useStaticQuery, graphql } from 'gatsby';

export const useRootProducts = () => {
  const {
    allStrapiFeatured: { nodes },
  } = useStaticQuery(graphql`
    {
      allStrapiFeatured {
        nodes {
          title
          subtitle
          googleEvent
          price
          url
          externalLink
          labels {
            id
            name
          }
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `);
  return nodes || [];
};
