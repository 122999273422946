import { useStaticQuery, graphql } from 'gatsby';

export const useServices = () => {
  const {
    allStrapiService: { nodes },
  } = useStaticQuery(graphql`
    {
      allStrapiService {
        nodes {
          key
          title
          subtitle
          info
          price
          url
          externalUrl
          rank
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `);

  return nodes.sort((a, b) => a.rank - b.rank) || [];
};
