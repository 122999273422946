import React from 'react';
import cn from 'classnames';

import type { ActionArgs } from '@customTypes/action';
import { Button, PriceButton } from '../../components';

import styles from './BannerItemMobile.module.scss';

interface BannerItemArgs {
  banner: {
    title: string;
    subtitle?: string;
    textColor: string;
    actions: ActionArgs[];
    ActionSubtitle?: string;
    mobileTitle: string;
  };
  bannerClassName?: string[];
  isH1?: boolean;
}

/*
TODO: нужен рефакторинг 
  * странно используем bannerClassName – это массив, но используем как строку
  * зачем нам прокидывать кастомные стили на баннер?
*/
export function BannerItemMobile({ banner, bannerClassName, isH1 }: BannerItemArgs) {
  return (
    <div className={styles.metadata}>
      <div className={styles.mainTitle}>
        {isH1 ? (
          <h1
            className={cn({
              [styles.title]: true,
              // @ts-ignore
              [bannerClassName]: !!bannerClassName,
            })}
            style={{ color: banner.textColor }}
            dangerouslySetInnerHTML={{
              __html: banner.mobileTitle ? banner.mobileTitle : banner.title,
            }}
          />
        ) : (
          <div
            className={cn({
              [styles.title]: true,
              // @ts-ignore
              [bannerClassName]: !!bannerClassName,
            })}
            style={{ color: banner.textColor }}
            dangerouslySetInnerHTML={{
              __html: banner.mobileTitle ? banner.mobileTitle : banner.title,
            }}
          />
        )}

        {banner.subtitle && banner.subtitle !== ' ' && (
          <div
            className={styles.subTitle}
            style={{ color: banner.textColor }}
            dangerouslySetInnerHTML={{
              __html: banner.subtitle,
            }}
          />
        )}
      </div>
      {banner.actions[0].actionText !== ' ' && (
        <div
          className={cn({
            [styles.bannerActions]: true,
            [styles.mobileBannerActions]: false,
          })}
          style={{ backgroundColor: banner.actions[0].buttonColor }}
        >
          {banner.actions[0].actionText &&
          banner.actions[0].actionText !== ' ' &&
          banner.actions[0].ActionSubtitle &&
          banner.actions[0].ActionSubtitle !== ' ' ? (
            <PriceButton action={banner.actions[0]} />
          ) : (
            <Button action={banner.actions[0]} />
          )}
        </div>
      )}
    </div>
  );
}
