import React from 'react';
import cn from 'classnames';
import blackArrow from '@images/icons/black-left-arrow.svg';
import arrow from '../../assets/NavigationArrow.svg';
import styles from './PrevArrow.module.scss';

export type ArrowType = {
  className?: string;
  style: React.CSSProperties;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  externalClassName?: string;
  black: boolean;
};

export function PrevArrow({ className, style, onClick, externalClassName, black }: ArrowType) {
  return (
    <div
      className={cn({
        [styles.prev]: true,
        [styles.black]: black,
        ...(className && { [className]: true }),
        ...(externalClassName && { [externalClassName]: true }),
      })}
      style={{ ...style }}
      onClick={onClick}
    >
      <div className={styles.greyzone}>
        <img style={{ transform: 'rotate(180deg)' }} src={black ? blackArrow : arrow} alt="arrow" />
      </div>
    </div>
  );
}
