import { useStaticQuery, graphql } from 'gatsby';

import type { StrapiPost } from '@customTypes/strapiData';

// TODO: типизировать
export const usePosts = () => {
  const {
    allStrapiPost: { nodes },
  } = useStaticQuery(graphql`
    {
      allStrapiPost {
        nodes {
          key
          title
          description
          url
          rank
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `);

  return nodes.sort((a: StrapiPost, b: StrapiPost) => a.rank - b.rank);
};
