import React from 'react';
import cn from 'classnames';
import blackArrow from '@images/icons/black-right-arrow.svg';
import arrow from '../../assets/NavigationArrow.svg';
import styles from './NextArrow.module.scss';
import type { ArrowType } from './PrevArrow';

export function NextArrow({ className, style, onClick, externalClassName, black }: ArrowType) {
  return (
    <div
      className={cn({
        [styles.next]: true,
        [styles.black]: black,
        ...(className && { [className]: true }),
        ...(externalClassName && { [externalClassName]: true }),
      })}
      style={{ ...style }}
      onClick={onClick}
    >
      <div className={styles.greyzone}>
        <img src={black ? blackArrow : arrow} alt="arrow" />
      </div>
    </div>
  );
}
