import photobookBanner from '@images/photobooks/photobookBanner.jpg';
import mobilePhotobookBanner from '@images/photobooks/mobilePhotobookBanner.jpg';

export const bannerContent = [
  {
    actions: [
      {
        ActionSubtitle: ' ',
        actionText: ' ',
        actionUrl: ' ',
        buttonColor: '#fff',
        buttonTextColor: '#000',
        externalUrl: false,
      },
    ],
    key: '1',
    mobileTitle: 'Фотокниги <br /> <b>с вашей историей</b>',
    rank: 1,
    showOnMobile: false,
    subtitle:
      'Закажите сегодня, и через несколько дней будете держать дорогие сердцу воспоминания в руках',
    textColor: '#213FB4',
    title: 'Фотокниги <br /> <b>с вашей историей</b>',
    image: {
      localFile: {
        photo: photobookBanner,
      },
    },
    mobileImage: {
      localFile: {
        photo: mobilePhotobookBanner,
      },
    },
  },
];
