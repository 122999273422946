import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { isFieldPresentInStrapi } from '@utils/isFieldPresentInStrapi';

import Slider from 'react-slick';

import { useBanners } from '@hooks/useBanners';
import type { BannersArgs } from '@customTypes/banners';

import { event } from '@analytics/gtm';
import { NextArrow } from '../components/arrows/NextArrow';
import { PrevArrow } from '../components/arrows/PrevArrow';

import { BannerItem } from './BannerItem';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './DesktopBanner.module.scss';

const customPaging = () => <div className={styles.dot} />;

const appendDots = (dots: any[]) => (
  <div className={styles.navigation}>
    <ul className={styles.dots}>
      {dots.map((dot) => (
        <li
          key={dot.key}
          className={cn({
            [styles.dot]: true,
            [styles.dotActive]: dot.props.className === 'slick-active',
          })}
        >
          {dot.props.children}
        </li>
      ))}
    </ul>
  </div>
);

const sliderSettings = {
  dots: true,
  autoplay: true,
  autoplaySpeed: 5000,
  customPaging,
  appendDots,
  dotsClass: styles.navigation,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  // TODO: типизировать Arrow (не обязательыне поля?)
  // @ts-ignore
  nextArrow: <NextArrow externalClassName={styles.arrowRight} />,
  // @ts-ignore
  prevArrow: <PrevArrow externalClassName={styles.arrowLeft} />,
  useTransform: false,
};

/* 
TODO: нужен рефакторинг 
  * очень странно используем customClassName 
  * зачем-то useBanners() внутри компонента всегда вызывается, хотя передаем баннеры одтельным пропсом
*/
export function DesktopBanner({ customContent, customClassName, isH1 }: BannersArgs) {
  const items = useBanners();

  const bannerContent = customContent ?? items;

  const handleBannerClick = (bannerUrl: string, googleEvent: string) => {
    if (isFieldPresentInStrapi(googleEvent)) {
      event(`event_site_${googleEvent}`, {
        url: bannerUrl,
      });
    }
  };

  const bannersToRender = bannerContent.map((banner: any) => (
    <div
      className={cn({
        [styles.banner]: true,
        // @ts-ignore
        [customClassName?.[1]]: !!customClassName,
      })}
      key={`${banner.key}`}
    >
      {banner?.actions[0]?.externalUrl ? (
        <a
          className={styles.overlay}
          target="_blank"
          rel="noreferrer"
          href={banner.actions[0].actionUrl !== ' ' ? banner.actions[0].actionUrl : null}
          onClick={() => handleBannerClick(banner.key, banner.googleEvent)}
        >
          {/* @ts-ignore */}
          <BannerItem banner={banner} bannerClassName={customClassName?.[0]} isH1={isH1} />
        </a>
      ) : (
        <Link
          className={styles.overlay}
          to={banner.actions[0]?.actionUrl !== ' ' ? banner.actions[0]?.actionUrl : null}
          onClick={() => handleBannerClick(banner.key, banner.googleEvent)}
        >
          {/* @ts-ignore */}
          <BannerItem banner={banner} bannerClassName={customClassName?.[0]} isH1={isH1} />
        </Link>
      )}
      {customContent ? (
        <img
          className={styles.image}
          src={banner.image?.localFile?.photo}
          alt="фотография баннера"
        />
      ) : (
        <GatsbyImage
          alt="фотография баннера"
          className={styles.image}
          imgStyle={{
            objectPosition: '10% center',
          }}
          image={banner.image.localFile.childImageSharp.gatsbyImageData}
        />
      )}
    </div>
  ));

  return (
    <div
      className={cn({
        [styles.banners]: true,
        [styles.bannersPadding]: customContent,
      })}
    >
      <div className={styles.wrapper}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Slider {...sliderSettings}>{bannersToRender}</Slider>
      </div>
    </div>
  );
}
