import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import cn from 'classnames';
import Slider from 'react-slick';

import { isFieldPresentInStrapi } from '@utils/isFieldPresentInStrapi';
import { useBanners } from '@hooks/useBanners';
import type { BannersArgs } from '@customTypes/banners';

import { event } from '@analytics/gtm';
import { BannerItemMobile } from './BannerItemMobile';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from './MobileBanner.module.scss';

const customPaging = () => <div className={styles.dot} />;

const appendDots = (dots: any[]) => (
  <div className={styles.navigation}>
    <ul className={styles.dots}>
      {dots.map((dot) => (
        <li
          key={dot.key}
          className={cn({
            [styles.dot]: true,
            [styles.dotActive]: dot.props.className === 'slick-active',
          })}
        >
          {dot.props.children}
        </li>
      ))}
    </ul>
  </div>
);

const sliderSettings = {
  dots: true,
  autoplay: true,
  autoplaySpeed: 5000,
  customPaging,
  appendDots,
  dotsClass: styles.navigation,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  useTransform: false,
  loading: 'eager',
  arrows: false,
};

export function MobileBanner({ customContent, customClassName, isH1 }: BannersArgs) {
  const items = useBanners(true);

  const bannerContent = customContent ?? items;

  const handleBannerClick = (bannerUrl: string, googleEvent: string) => {
    if (isFieldPresentInStrapi(googleEvent)) {
      event(`event_site_${googleEvent}`, {
        url: bannerUrl,
      });
    }
  };
  const bannersToRender = bannerContent.map((banner: any) => (
    <div className={styles.banner} key={`mob-banner-${banner.key}`}>
      {banner.actions.length === 1 &&
        (banner.actions[0].externalUrl ? (
          <a
            className={styles.overlay}
            target="_blank"
            rel="noreferrer"
            href={banner.actions[0].actionUrl !== ' ' ? banner.actions[0].actionUrl : null}
            onClick={() => handleBannerClick(banner.key, banner.googleEvent)}
          >
            <BannerItemMobile banner={banner} bannerClassName={customClassName} isH1={isH1} />
          </a>
        ) : (
          <Link
            className={styles.overlay}
            to={banner.actions[0].actionUrl !== ' ' ? banner.actions[0].actionUrl : null}
            onClick={() => handleBannerClick(banner.key, banner.googleEvent)}
          >
            <BannerItemMobile banner={banner} bannerClassName={customClassName} isH1={isH1} />
          </Link>
        ))}
      {customContent ? (
        <img
          className={styles.image}
          src={banner.mobileImage?.localFile?.photo}
          alt="фотография баннера"
        />
      ) : (
        <GatsbyImage
          alt="фотография баннера"
          className={styles.image}
          imgStyle={{
            objectPosition: '10% center',
            borderRadius: '16px',
          }}
          image={banner.mobileImage.localFile.childImageSharp.gatsbyImageData}
        />
      )}
    </div>
  ));

  return (
    <div className={styles.banners}>
      <div className={styles.wrapper}>
        <Slider
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...sliderSettings}
        >
          {bannersToRender}
        </Slider>
      </div>
    </div>
  );
}
